import { add, format, getDay } from "date-fns"
import { da } from "date-fns/locale"
import { motion } from "framer-motion"
import { navigate } from "gatsby"
import { concat, findIndex, propEq } from "ramda"
import React, { FC, useContext, useState } from "react"
import AppContext from "../AppContext"
import { animScale } from "../utils/utils"
import MobileMenu from "./MobileMenu"

const logo = require("../../static/logo/logo.svg") as string

export type MenuOption = {
  name: string
  href: string
  key: string
}

export const getBestillingLink = (aar: number, uge: number) => {
  let now = new Date()
  const day = getDay(now)

  // Hvis det er efter fredag skal vi vise menuen fra næste uge
  if (day > 4 || day === 0) now = add(now, { weeks: 1 })
  const denneUge = Number(format(now, "w", { locale: da }))

  // Vælg den laveste uge værdi så vi ikke kommer til at vise næste uges menu for tidligt
  const lavesteUge = Math.min(uge, denneUge)

  return `/bestil/${aar}/uge-${lavesteUge}/`
}

export const getMainMenuOptions = (aar: number, uge: number) => {
  const menu: MenuOption[] = [
    { name: "Forside", href: "/", key: "ForsideLink" },
    // {
    //   name: "Uge menu",
    //   href: getBestillingLink(aar, uge),
    //   key: "UgeMenuLink",
    // },
    // { name: "Færdigretter", href: "/faerdigretter/", key: "FaerdigretterLink" },
    { name: "Catering", href: "/catering/", key: "CateringLink" },
    {
      name: "Måltidssalater",
      href: "/maaltidssalater/",
      key: "MaaltidssalaterLink",
    },
    { name: "Kontakt", href: "/kontakt/", key: "KontaktLink" },
  ]

  return menu
}

const topMenuOptions: MenuOption[] = [
  // { name: "Kurv", href: "/kurv", key: "KurvLink" },
  // { name: "Kontakt", href: "/kontakt", key: "KontaktLink" },
]

const getIndex = (aar: number, uge: number) => {
  if (typeof window !== `undefined`) {
    let currentPath = window.location.pathname
    if (!currentPath.endsWith("/")) currentPath += "/"
    return findIndex(propEq("href", currentPath))(getMainMenuOptions(aar, uge))
  } else {
    return 2
  }
}

const NavigationBar: FC<{ aar: number; uge: number }> = ({ aar, uge }) => {
  const [selected, setSelected] = useState(getIndex(aar, uge))
  const { loading } = useContext(AppContext)

  const MenuOption: FC<{
    option: MenuOption
    selected: boolean
    onClick: () => void
  }> = ({ option, selected, onClick }) => (
    <motion.button
      className="px-4 font-semibold text-center uppercase focus:outline-none"
      whileHover={{
        scale: animScale,
      }}
      onClick={onClick}
    >
      {option.name}
      {selected && (
        <motion.div className="border-b-4 rounded border-main-orange" />
      )}
    </motion.button>
  )

  return (
    <div>
      <div className="hidden md:block">
        <div className="flex flex-col items-center max-w-6xl pt-2 text-main-blue">
          <motion.img
            role="button"
            className="pb-2"
            width={90}
            height={90}
            alt="logo"
            src={logo}
            whileHover={{
              scale: animScale,
            }}
            animate={
              loading
                ? {
                    scale: [1, animScale, 1],
                  }
                : {}
            }
            transition={{
              loop: loading ? Infinity : 0,
            }}
            onClick={() => navigate("/")}
          />

          {/*<nav className="flex justify-between m-2 text-sm">*/}
          {/*  <div className="flex">*/}
          {/*    {topMenuOptions.map(({ name, href, key }) => (*/}
          {/*      <Link to={href} className="mx-2" key={key}>*/}
          {/*        {name}*/}
          {/*      </Link>*/}
          {/*    ))}*/}
          {/*  </div>*/}
          {/*</nav>*/}
          <nav className="flex justify-center pb-2">
            <div className="flex items-end text-sm text-main-blue">
              {getMainMenuOptions(aar, uge).map((option, index) => (
                <MenuOption
                  option={option}
                  key={option.key}
                  selected={index === selected}
                  onClick={() => {
                    navigate(option.href)
                    setSelected(index)
                  }}
                />
              ))}
            </div>
          </nav>
        </div>
      </div>

      <MobileMenu
        menuOptions={concat(
          getMainMenuOptions(aar, uge).filter(
            (option) => option.name !== "Logo"
          ),
          topMenuOptions
        )}
      />

      <div className="w-full h-4 bg-main-blue" />
    </div>
  )
}

export default NavigationBar
