import React from "react"
import NavigationBar from "./navigationbar"
import SEO from "./seo"

const Layout: React.FC<{ title: string; aar: number; uge: number }> = ({
  title,
  aar,
  uge,
  children,
}) => {
  return (
    <div className="w-full max-w-6xl mx-auto">
      <NavigationBar uge={uge} aar={aar} />
      <div className="mx-auto">
        <main>
          <SEO title={title} />
          {children}
        </main>
        <Footer />
      </div>
    </div>
  )
}

const Footer = () => (
  <footer className="flex justify-center py-4 text-xs bg-white">
    <div className="flex gap-0.5">
      <span>Se fødevarestyrelsens smiley-rapporter:</span>
      <a
        href="https://www.findsmiley.dk/951531"
        className="hover:underline"
        target="_blank"
        rel="noreferrer"
      >
        catering,
      </a>
      <a
        href="https://www.findsmiley.dk/966908"
        className="hover:underline"
        target="_blank"
        rel="noreferrer"
      >
        drikkevarer
      </a>
    </div>
    <span className="pl-2">-</span>
    <div className="pl-2">© Øland {new Date().getFullYear()} </div>
  </footer>
)

export default Layout
