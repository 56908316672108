import { Link, navigate } from "gatsby"
import React from "react"
import { MenuOption } from "./navigationbar"

const logo = require("../../static/logo/logo.svg") as string

type MobileMenuProps = {
  menuOptions: MenuOption[]
}

const MobileMenu: React.FC<MobileMenuProps> = (props) => {
  return (
    <div className="flex items-center justify-center text-main-blue md:hidden">
      <img
        role="button"
        className="p-2 cursor-pointer"
        width={90}
        height={90}
        alt="logo"
        src={logo}
        onClick={() => navigate("/")}
      />
      <div className="flex flex-wrap">
        {props.menuOptions.map(({ name, href, key }) => (
          <Link to={href} className="mx-1 sm:mx-2" key={key}>
            {name}
          </Link>
        ))}
      </div>
    </div>
  )
}

export default MobileMenu
